import classes from "./Footer.module.css";

import { MdOutlineWatchLater } from "react-icons/md";

import { AiOutlineMail } from "react-icons/ai";

import {
  MdCall,
  MdAccessTimeFilled,
  MdEmail,
  MdLocationPin,
} from "react-icons/md";

import {
  BsFacebook,
  BsTwitter,
  BsLinkedin,
  BsYoutube,
  BsEnvelope,
} from "react-icons/bs";

import { useHistory } from "react-router-dom";

function Footer() {
  const redirectDiracAIHandler = () => {
    window.open("https://diracai.com/services/", "_blank");
  };

  const history = useHistory();

  const homePageHandler = () => {
    history.push("/Privacypolicy");
  };

  const aboutPageHandler = () => {
    history.push("/TermsofUse");
  };

  const newsHandler = () => {
    history.push("/CookiesPolicy");
  };

  const contactusHandler = () => {
    history.push("/contactus");
  };

  const aboutusPageHandler = () => {
    history.push("/AboutUs");
  };

  const helpcenterPageHandler = () => {
    history.push("/HelpCenter");
  };

  const refundPageHandler=()=>{

    history.push("/RefundPolicy");
  }


  const twitterHandler = () => {
    window.open(
      "https://twitter.com/DiracAI1",
      "_blank"
    );
  };


  const facebookHandler = () => {
    window.open(
      "https://www.facebook.com/diracai",
      "_blank"
    );
  };

 const youtubeHandler = () => {
    window.open(
      "https://www.youtube.com/@DiracAIVlogs",
      "_blank"
    );
  };

 const linkedInHandler = () => {
    window.open(
      "https://www.linkedin.com/company/diracai-services/?viewAsMember=true",
      "_blank"
    );
  };
	





  return (
    <div className={classes.footer}>
      <div className={classes.mainFooterContainer}>
        <div className={classes.block1}>
          <div className={classes.otherLink}>DiracAI</div>
          {/*onClick={homePageHandler}*/}
          <button className={classes.cont1} onClick={aboutusPageHandler}>
            {/* <div className={classes.cont1Icon}></div> */}
            <div className={classes.cont1Text}>
              <div className={classes.address}>
  <div className={classes.header}><strong>Head Office:</strong></div>
  <div>HIG-306, K-5, Kalinga Vihar,</div>
  <div>Bhubaneswar, Odisha, India</div>
  <div>Pin : 751007</div>
  <div>Phone : +91 7008639757</div>
  <div>Email : contact@diracai.com</div>
  </div>
  <div className={classes.address}>
  <div className={classes.header}><strong>Branch Office:(Pune)</strong></div>
  <div>L 202, 2nd Floor, Sai Dreams Phase 2,</div>
  <div>Kate Vasti Road, Pimple Saudagor,</div>
  <div>Kunj Colony, Pimpri Chinchwad,</div>
  <div>Maharashtra, 411027, India</div>
  </div>

<div className={classes.address}>
  <div className={classes.header}><strong>Branch Office:(France)</strong></div>
  <div>11, Presquile,</div>
  <div>Andre-Malraux</div>
  <div>Strasbourg,</div>
  <div>France, 67100</div>
  </div>

</div>
          </button>

          {/* onClick={aboutPageHandler} */}
          <button className={classes.cont1} onClick={helpcenterPageHandler}>
            {/* <div className={classes.cont1Icon}></div> */}
            {/* <div className={classes.cont1Text}>Pin : 751007</div> */}
          </button>

          {/*onClick={newsHandler}*/}
          <button className={classes.cont1} onClick={contactusHandler}>
            {/* <div className={classes.cont1Icon}></div> */}
            {/* <div className={classes.cont1Text}>Phone : +91 7008639757</div> */}
          </button>

          <button className={classes.cont1} onClick={contactusHandler}>
            {/* <div className={classes.cont1Icon}></div> */}
            {/* <div className={classes.cont1Text}>Email : contact@diracai.com</div> */}
          </button>

          {/* <button className={classes.cont1} onClick={contactusHandler}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Disclaimer</div>
          </button> */}

          {/*onClick={contactusHandler}*/}
          {/* <button className={classes.cont1} >
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Contact Us</div>
          </button> */}

          
        </div>

        <div className={classes.block2}>
          <div className={classes.otherLink}>Terms of Service</div>
          {/*onClick={homePageHandler}*/}
          <button className={classes.cont1} onClick={homePageHandler}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Privacy Policy</div>
          </button>

          {/*onClick={aboutPageHandler}*/}
          <button className={classes.cont1} onClick={aboutPageHandler}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Terms of Use</div>
          </button>

          {/*onClick={newsHandler}*/}
          <button className={classes.cont1} onClick={newsHandler}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Cookies Policy</div>
          </button>

          <button className={classes.cont1} onClick={contactusHandler}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Disclaimer</div>
          </button>

          <button className={classes.cont1} onClick={refundPageHandler}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Refund Policy</div>
          </button>

          {/*onClick={aboutPageHandler}*/}
          <button className={classes.cont1} onClick={helpcenterPageHandler}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Help Center</div>
          </button>

          {/*onClick={contactusHandler}*/}
          {/* <button className={classes.cont1} >
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Contact Us</div>
          </button> */}
        </div>

        <div className={classes.block3}>
          <div className={classes.otherLink}>Services</div>

          <div className={classes.cont2}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Custom Web Development</div>
          </div>

          <div className={classes.cont2}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Mobile Development</div>
          </div>

          <div className={classes.cont2}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Data Analytics Solutions</div>
          </div>

          <div className={classes.cont2}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>
              Ed-Tech Platform Development
            </div>
          </div>

          <div className={classes.cont2}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Database management</div>
          </div>

          <div className={classes.cont2}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Software Training</div>
          </div>

          {/* <div className={classes.cont2}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>Transparency Crusader</div>
          </div>

          <div className={classes.cont2}>
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>
            Consumer Rights Defender

            </div>
          </div> */}
        </div>
        <div className={classes.block4}>
          <div className={classes.otherLink}>Contact Us</div>

          <div className={classes.cont4} >
            <BsTwitter className={classes.cont4Icon} onClick={twitterHandler}/>
            <BsFacebook className={classes.cont4Icon} onClick={facebookHandler}/>
            <BsYoutube className={classes.cont4Icon} onClick={youtubeHandler}/>
            <BsLinkedin className={classes.cont4Icon} onClick={linkedInHandler}/>
          </div>
        </div>
      </div>

      {/* <div className={classes.bottomBar}>
        <div className={classes.horiLine}></div>

        <div className={classes.text1}>
          Copyright 2023 © hellotoppers.com All rights reserved.
        </div>
      </div> */}
    </div>
  );
}

export default Footer;
